import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from "aos";
//sections
export default {
  name: "favorites",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{
    ...mapGetters({
      favoriteList: 'basket/favorites'
    }),
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods:{
    ...mapActions({

    }),
    ...mapMutations({
      removeFavoriteProduct: `basket/REMOVE_ITEM_FROM_FAVORITES`
    }),
    removeFavorite(product){
      this.removeFavoriteProduct(product)
    }
  }
}